import React from 'react'
import styled, { css } from 'styled-components'
import RadioButton from '../../../components/RadioButton'
import { AiOutlineEdit } from 'react-icons/ai'

export default function SectionItem({ children, title, selectable, selected, display=true, onClick }) {
    if (!display) return null
    return (
        <SectionItemView selected={selected}>
            <Header selectable={selectable} onClick={onClick}>
                {selectable && <RadioButton selected={selected}/>}
                <div>{title}</div>
                <IconView>
                    <AiOutlineEdit size={25}/>
                </IconView>
            </Header>
            <ContentView>
                {children}
            </ContentView>
        </SectionItemView>
    )
}

const SectionItemView = styled.div`
    background-color: lightgrey;
    margin-bottom: 10px;
    ${p=>p.selected && css`border: solid 2px black;`}
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
`
const Header = styled.div`
    height: 30px;
    padding: 10px;
    display: flex;
    align-items: center;
    background: lightgrey;
    ${p=>p.selectable && css`
        :hover{
            cursor: pointer;
        }
    `}
`
const ContentView = styled.div`
    flex: 1;
    background-color: #ededed;
    padding: 10px;
`
const IconView = styled.div`
    margin-left: auto;
`
