import React, { useState, useEffect } from 'react'
import { useLoadScript } from '@react-google-maps/api'
import { addMarkers, requestLincr } from '../../reducer/map'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import PloygonGoogleMaps from '../../maps/PloygonGoogleMaps2'
import Content from './Content'
import CustomMarkers from './CustomMarkers'
import { MdOutlineArrowBackIos } from 'react-icons/md'
import { changeScreen, WEB_ADMIN_DASH_BOARD_SCREEN } from '../../reducer/user'
import { changeSelectedServiceGroupID, requestGeocode, addToCurServiceGroup, addCoordsToCustomMarkers, changeSelectedPolylineId } from '../../reducer/map'
import { SectionItem } from '../../components'
import Coordinate from './components/Coordinate'

const SERVICE_GROUP_REGIONS = 'service_group_regions'
const CUSTOM_PINS = 'custom_pin'

const uid = function(){
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
}


export default function WebAdminApp() {
    const dispatch = useDispatch()
    const { startPoint, selectedServiceGroup, selectedPolylineId } = useSelector(mapState)
    const [markers,setMarkers] = useState([])
    const [selectedScreen,setSelectedScreen] = useState(SERVICE_GROUP_REGIONS)
    const [address,setAddress] = useState('')
    const [selectedGroupId, setSelectedCustomGroup] = useState(null)

    function onAddLatLong(e){
        const { latLng } = e
        const lat = latLng.lat()
        const lng = latLng.lng()
        let path = { lat: lat, lng:lng }
        setMarkers([{ coords: path }])
    }

    function onGoBack(){
        dispatch(changeScreen(WEB_ADMIN_DASH_BOARD_SCREEN))
    }

    function onChangeAdress(e){
        setAddress(e.target.value)
    }

    function onSearchGeoLocation(){
        requestGeocode(address, dispatch)
    }

    function isSelected(screen){
        return screen === selectedScreen
    }

    function onClickOption(screen){
        if (selectedScreen !== screen) setSelectedScreen(screen)
    }

    function onClickMap(e){
        const lat = e.latLng.lat()
        const lng = e.latLng.lng()
        if (selectedScreen === SERVICE_GROUP_REGIONS) {
            if (selectedServiceGroup === null) return alert("Please select a service group")
            dispatch(addToCurServiceGroup({ lat, lng }, selectedServiceGroup))
        }
        else {
            if (!selectedPolylineId) return alert("Please select groupID")
            dispatch(addCoordsToCustomMarkers(selectedPolylineId,{ lat, lng }))
        }
    }

    function onChangePolylineId(id){
        dispatch(changeSelectedPolylineId(id))
    }

    function onClick(e){
        const lat = e.latLng.lat()
        const lng = e.latLng.lng()
        if (selectedServiceGroup === null) return alert("Please select a service group")
        dispatch(addToCurServiceGroup({ lat, lng }, selectedServiceGroup))
    }

    return (
        <WebAdminAppView>
            <ContentView>
                <ContentHeaderView>
                    <BackButton onClick={onGoBack}>
                        <MdOutlineArrowBackIos />
                        <div>Back to Lincrs List</div>
                    </BackButton>
                    <SectionItem title="Start Location">
                        <StartLocationView onClick={onSearchGeoLocation}>
                            <input placeholder='Search for an address' onChange={onChangeAdress} value={address}/>
                            <div>
                                <div>{!startPoint.lng ? 'Search Address' : 'Change Address'}</div>
                            </div>
                        </StartLocationView>
                        {startPoint.lng !== null && (
                            <Coordinate lat={startPoint.lat} lng={startPoint.lng} showDelete={false} />
                        )}
                    </SectionItem>
                    <Options>
                        <Option
                            selected={isSelected(SERVICE_GROUP_REGIONS)}
                            onClick={()=>onClickOption(SERVICE_GROUP_REGIONS)}
                        >
                            <div>Service Groups</div>
                        </Option>
                        {/* <Option
                            selected={isSelected(CUSTOM_PINS)}
                            onClick={()=>onClickOption(CUSTOM_PINS)}
                        >
                            <div>Land Marks</div>
                        </Option> */}
                    </Options>
                </ContentHeaderView>
                {selectedScreen === SERVICE_GROUP_REGIONS && <Content />}
                {selectedScreen === CUSTOM_PINS && <CustomMarkers
                        onSelectGroup={(id)=>onChangePolylineId(id)}
                        selectedGroupId={selectedPolylineId}
                    />}
            </ContentView>
            <MapView>
                <PloygonGoogleMaps
                    markers={markers}
                    allSiteInfo={[]}
                    onClick={onAddLatLong}
                    polygonArr={[]}
                    count={0}
                    onClickMap={onClickMap}
                />
            </MapView>
        </WebAdminAppView>
    )
}

function mapState(state){
    return {
        startPoint: state.Map.startPoint,
        selectedServiceGroup: state.Map.selectedServiceGroup,
        selectedPolylineId: state.Map.selectedPolylineId
    }
}

const WebAdminAppView = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
`
const MapView = styled.div`
    flex: 1;
    background-color: lightgrey;
    margin: 10px;
    overflow: hidden;
    border-radius:10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
`
const ContentView = styled.div`
    max-width: 600px;
    width: 600px;
    @media (max-width: 768px) {
        max-width: 400px;
    }
    min-width: 200px;
    height: 100%;
    background-color: white;
    overflow: scroll;
`
const DragIndicator = styled.div`
    height: 100px;
    width: 2px;
    background-color: red;
    margin: auto 0px;
    cursor: col-resize;
    margin-left: 3px;
`
const BackButton= styled.div`
    width: fit-content;
    margin-right: auto;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    >div{
        margin-left: 10px;
    }
    :hover{
        cursor: pointer;
    }

`
const StartLocationView = styled.div`
    >input{
        width: 90%;
        border: 1px solid;
        padding: 5px;
        height: 30;
    }
    >div{
        height: 30;
        width: 200px;
        background-color: lightgreen;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        :hover{
            cursor: pointer;
        }
        margin-left: 10px;
    }
    display: flex;
    align-items: center;
`
const ContentHeaderView = styled.div`
    width: 95%;
    margin: 0px auto;
    margin-top: 10px;
    height: fit-content;
`
const Options = styled.div`
    display: flex;
`
const Option = styled.div`
    flex: 1;
    background-color: lightgrey;
    padding: 10px;
    ${p=>p.selected && css`
    background-color: #80e1a1;
    `}
    :hover{
        cursor: pointer;
    }
`
