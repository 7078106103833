import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAITb-wpzPd7iEebqUUcHy2hl4O3ZEtWQs",
    authDomain: "telawatch.firebaseapp.com",
    projectId: "telawatch",
    storageBucket: "telawatch.appspot.com",
    messagingSenderId: "74298200554",
    appId: "1:74298200554:web:5ae051d3075a8e19666293",
    measurementId: "G-LVCELC18HD"
};

firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore()
const auth = firebase.auth();

export const signIn = async (email, password) => {
    await auth.signInWithEmailAndPassword(email, password)
}

export const getLincr = async (lincrID='test-site')=>{
    const lincr = await firestore.collection('Lincrs').doc(lincrID).get()
    return {
        ...lincr.data(),
        lincrID: lincr.id
    }
}

export const saveServiceGroupRegions = async (lincrID,deviceName, serviceGroupRegions, mapCenter) => {
    console.log({ serviceGroupRegions })
    try {
        await firestore.collection('Lincrs').doc(lincrID).set({
            [deviceName]: {
                serviceGroupRegions,
                mapCenter
            }
        },{ merge: true })
        alert('Changes saved successfully')
    } catch (error) {
        alert('There was an error saving your changes. Please try again')
    }

}

export {
    auth,
    firestore
}
