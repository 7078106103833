import React, { useState } from 'react'
import styled from 'styled-components'
import SectionItem from './components/SectionItem'
import { useSelector, useDispatch } from 'react-redux'
import { createCustomGroup, polylineSplitGroup } from '../../reducer/map'
import CustomCordinate from './components/CustomCordinate'
import { changePolylineShowIcon, changePolyLineMarkerIcon, deletePolylineMarker } from '../../reducer/map'
import { DARK_TRIANGLE, TRIANGLE, RED_PIN, RED_CIRCLE } from '../../constants/markerIcons'

function NewGroup({ onCreateGroup }){
    const [title,setTitle] = useState('')
    const [body,setBody] = useState('')
    const [color,setColor] = useState('')
    const [icon,setIcon] = useState(RED_PIN)

    function onChange(e){
        const { name, value } = e.target
        if (name === 'title') setTitle(value)
        else if (name === 'body') setBody(value)
        else if (name === 'color') setColor(value)
        else if (name === 'icon') setIcon(value)
    }

    function onsubmit(){
        setTitle('')
        setBody('')
        setColor('')
        onCreateGroup(title, body, color, icon)
    }

    return (
        <NewGroupView>
            <InputWithTitle>
                <div>Title</div>
                <input name='title' value={title} onChange={onChange}/>
            </InputWithTitle>
            <InputWithTitle>
                <div>Notes</div>
                <textarea name='body' onChange={onChange} value={body}/>
            </InputWithTitle>
            <InputWithTitle>
                <div>Fill Color:</div>
                <input id='color' type='color' name='color' onChange={onChange} value={color}/>
            </InputWithTitle>
            {/* <InputWithTitle>
                <div>Default Marker:</div>
                <div style={{ display: 'flex', }}>
                    <Image selected={icon === RED_PIN} onClick={()=>onChange({ target: { name: 'icon', value: RED_PIN}})}>
                        <img src={RED_PIN} />
                    </Image>
                    <Image selected={icon === TRIANGLE} onClick={()=>onChange({ target: { name: 'icon', value: TRIANGLE}})}>
                        <img src={TRIANGLE} />
                    </Image>
                    <Image selected={icon === DARK_TRIANGLE} onClick={()=>onChange({ target: { name: 'icon', value: DARK_TRIANGLE}})}>
                        <img src={DARK_TRIANGLE} />
                    </Image>
                </div>
            </InputWithTitle> */}
            <Button onClick={onsubmit}>
                <div>Add Group</div>
            </Button>
        </NewGroupView>
    )
}


function mapState(state){
    return {
        otherMapMarkers: state.Map.otherMapMarkers,
    }
}

export default function CustomMarkers({ onSelectGroup, selectedGroupId }) {
    const dispatch = useDispatch()
    const { otherMapMarkers } = useSelector(mapState)

    function onChangeShowIcon(polyLineId, markerId,showIcon){
        dispatch(changePolylineShowIcon(polyLineId, markerId, showIcon))
    }

    function onChangeIcon(polyLineId, markerId,newIcon){
        dispatch(changePolyLineMarkerIcon(polyLineId, markerId,newIcon))
    }

    function onDeleteMarker(polyLineId, markerId){
        dispatch(deletePolylineMarker(polyLineId, markerId))
    }

    function onSplitGroup(polyLineId, markerId, title){
        dispatch(polylineSplitGroup(polyLineId, markerId, title))
    }

    function renderItem(item){
        const selected = item.id === selectedGroupId
        return (
            <SectionItem
                title={item.title}
                onClick={()=>onSelectGroup(item.id)}
                selectable
                selected={selected}
                key={item.id}
            >
                {!item.path?.length && <div>No Coordinates</div>}
                {(item.path || []).map(x=>{
                    return (
                        <CustomCordinate
                            lat={x.coords.lat}
                            lng={x.coords.lng}
                            onChangeShowIcon={()=>onChangeShowIcon(item.id, x.id, !x.showIcon)}
                            showIcon={x.showIcon}
                            icon={x.icon}
                            onChangeIcon={(newIcon)=>onChangeIcon(item.id,x.id, newIcon)}
                            onDeleteMarker={()=>onDeleteMarker(item.id, x.id)}
                            focus={x.focus}
                            onSplitGroup={(title)=>onSplitGroup(item.id, x.id, title)}
                        />
                        // <div>lat: {x.coords.lat} lng: {x.coords.lng}</div>
                    )
                })}
            </SectionItem>
        )
    }

    function onCreateNewGroup(title,body,color, icon){
        dispatch(createCustomGroup(title,body,color, icon))
    }

    return (
        <CustomMarkersView>
            <AddGroup>
                <b>
                    <div>Add Land Marker Group</div>
                </b>
                <NewGroup onCreateGroup={onCreateNewGroup}/>
            </AddGroup>
            <br />
            <b>List of Land Marker Groups</b>
            <br />
            {otherMapMarkers.map(renderItem)}
        </CustomMarkersView>
    )
}

const CustomMarkersView = styled.div`
    width: 95%;
    margin: 0px auto;
`
const AddGroup = styled.div`
    box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
    margin-top: 10px;
    background-color: #ededed;
    >b{
        margin-bottom: 7px;
    }
    padding: 10px;
`
const NewGroupView = styled.div`
    display: flex;
    flex-direction: column;
`
const InputWithTitle = styled.div`
    text-align: left;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    >input{
        padding: 5px;
        border: solid 1px;
        border-radius: 5px;
        margin-bottom: 5px;
    }
    > #color{
        border: 1px solid;
        border-radius: 5px;
        padding: 0px;
        width: 100px;
        margin-top: 5px;
    }
    >textarea {
        resize: none;
        border: solid 1px;
        padding: 5px;
        border-radius: 5px;
        height: 50px;
        margin-bottom: 5px;
    }
    >select{
        border: solid 1px;
        padding: 5px;
        border-radius: 5px;
        margin-bottom: 5px;
    }
    >img{
        height: 40px;
        width: 40px;
    }
`
const Button = styled.div`
    width: 150px;
    height: 20px;
    padding: 5px;
    background-color: lightgreen;
    margin: 10px auto 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    :hover {
        cursor: pointer;
    }
`
const Image = styled.div`
    > img{
        height: 40px;
        width: 40px;
        ${p=>p.selected && 'border: solid 1px; padding: 2px;'}
        margin-right: 10px;
    }
`
