import React, { useState, useEffect } from 'react'
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Map from './Map'
import Marker from './Marker'
import RedPin from '../markerIcons/red.png';
import YellowPin from '../markerIcons/yellow.png';
import GreenPin from '../markerIcons/green.png';
import { useSelector, useDispatch } from 'react-redux'
import { addMarkers, addCoordinate, addToCurServiceGroup, updateLatLngOfMarker, changeSelectedServiceGroupID } from  '../reducer/map'

const API_KEY = 'AIzaSyDiZgNy7V8roScrJNfRWWq6CGmzmiu1IsE'
const image =
    "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";

function StatusComponent({ message='Default message' }){
    return <div>{message}</div>
}

function Render({ status }){
    return <div>{status}</div>
}

const render = (status) => {
    return <Render status={status}/>
};

const uid = function(){
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
}


export default function GoogleMap({ allSiteInfo, showMapClicks, count, onClickMap }) {
    const dispatch = useDispatch()
    const { markers, selectedServiceGroup, selectedDeviceId } = useSelector(mapState)

    const [zoom, setZoom] = React.useState(3); // initial zoom
    const [center, setCenter] = React.useState({
        lat: 0,
        lng: 0,
    });

    const onIdle = (m) => {
        setZoom(m.getZoom());
        setCenter(m.getCenter().toJSON());
    };

    function onClickMarker({ lat, lng, ...rest }){
        const site = allSiteInfo.find(x=>{
            const coords = x.coords
            return coords.lat === lat && coords.lng === lng
        })
        // setPolygonCoords((prev)=>[{ coords: { lat, lng }, id: uid(), }, ...prev])
    }

    function onDrag(e, { id }){
        const lat = e.latLng.lat()
        const lng = e.latLng.lng()
        const marker = markers.find(x=>x.id === id)
        if (marker) {
            dispatch(updateLatLngOfMarker(id, { lat, lng }))
        }
    }

    function onClickPolygon(e,serviceGroupRegionID){
        dispatch(changeSelectedServiceGroupID(serviceGroupRegionID))
    }

    return (
        <Wrapper apiKey={API_KEY} render={render} style={{ flexDirection:'row' }}>
            <div style={{ flexDirection:'row', width: '100%', height: '100%', display: 'flex' }}>
                <Map
                    zoom={zoom}
                    center={center}
                    onClick={onClickMap}
                    onIdle={onIdle}
                    style={{ width: '100%', height: '100%' }}
                    clusterMarkers={[]}
                    onClickMarker={(coords)=>onClickMarker({lat: coords.lat, lng: coords.lng})}
                    useCluster={false}
                    onClickPolygon={onClickPolygon}
                >
                    {markers?.map(({ color, coords, id })=>{
                        return (
                            <Marker
                                position={coords}
                                id={id}
                                key={id}
                                // icon={{ url: RedPin, size: {width: 60, height: 60 }, scaledSize: {width: 30, height: 50}, }}
                                // scaledSize={new window.google.maps.Size(10,15)}
                                pinColor="yellow"
                                fillColor="blue"
                                onClick={()=>onClickMarker({lat: coords.lat, lng: coords.lng})}
                                draggable
                                onDrag={onDrag}
                            />
                        )
                    })}
                </Map>
            </div>
        </Wrapper>
    )
}

function mapState(state){
    return {
        markers: state.Map.curServiceGroupRegion,
        selectedServiceGroup: state.Map.selectedServiceGroup,
        selectedDeviceId: state.Map.selectedDevice
    }
}
