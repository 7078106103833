import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { SectionItem } from '../../components'
import DeviceItem from './components/DeviceItem'
import { selectDevice, changeSelectedServiceGroupID, requestGeocode, addPinToStartLocation, deletServiceGroupMarker, saveServiceGroupRegionsToFirebase, addToCurServiceGroup } from '../../reducer/map'

import { changeScreen, WEB_ADMIN_DASH_BOARD_SCREEN } from '../../reducer/user'

const SERVICE_GROUP_REGIONS = 'service_group_regions'
const CUSTOM_PINS = 'custom_pin'

function Coordinate({ lat, lng, onDeleteMarker, showDelete=true, isNew }){
    return (
        <CoordinateView>
            <Coords isNew={isNew}>
                <div>lat: {lat}</div>
                <div>lng: {lng}</div>
            </Coords>
            {showDelete && <DeleteButton onClick={onDeleteMarker} isNew={isNew}>
                <div>x</div>
            </DeleteButton>}
        </CoordinateView>
    )
}


export default function Content() {
    const dispatch = useDispatch()
    const [selectedScreen,setSelectedScreen] = useState(SERVICE_GROUP_REGIONS)
    const { deviceList, selectedDevice, selectedServiceGroups, selectedServiceGroup, serviceGroupRegions, startPoint, lincr } = useSelector(mapState)
    const [address,setAddress] = useState('')
    const ModemsByCardNames = lincr?.[selectedDevice]?.ModemsByCardNames

    function onSelectDevice(deviceName){
        dispatch(selectDevice(deviceName))
    }

    function onChangeSelectedServiceGroup(id){
        dispatch(changeSelectedServiceGroupID(id))
    }

    function onChangeAdress(e){
        setAddress(e.target.value)
    }

    function onSearchGeoLocation(){
        requestGeocode(address, dispatch)
    }

    function onAddPin(){
        // if (startPoint.lat && startPoint.lng) dispatch(addPinToStartLocation())
        if (startPoint.lat && startPoint.lng) dispatch(addToCurServiceGroup(startPoint, selectedServiceGroup))
        else alert('Please Add A Starting Point')
    }

    function onDeleteMarker(markerId, serviceGroupId){
        dispatch(deletServiceGroupMarker(markerId, serviceGroupId))
    }

    function onSaveServiceGroupRegions(){
        const lincrID = lincr.lincrID
        if (lincrID && selectedDevice && serviceGroupRegions) saveServiceGroupRegionsToFirebase(lincrID, selectedDevice,serviceGroupRegions, startPoint)
        else alert("Error you have not made any changes")
    }

    function onGoBack(){
        dispatch(changeScreen(WEB_ADMIN_DASH_BOARD_SCREEN))
    }

    function renderDeviceItems(item){
        return (
            <DeviceItem
                name={item.DisplayName}
                selected={item.DisplayName === selectedDevice}
                onClick={()=>onSelectDevice(item.DisplayName)}
                key={item.id}
            />
        )
    }

    function renderServiceGroups(item,i){
        const region = serviceGroupRegions[item.id] || []
        const selected = item.id === selectedServiceGroup
        const name = ModemsByCardNames?.[item.id]
        let title = name ? `${item.id} | ${name}` : item.id
        return (
            <SectionItem
                title={title}
                selectable
                selected={selected}
                key={item.id}
                onClick={()=>onChangeSelectedServiceGroup(item.id)}
            >
                {!region.length && <div style={{ marginBottom: 10 }}>No coordinates.{!selected && ' Select this service group to add markers'}</div>}
                {region.map(x=>{
                    return (
                        <Coordinate
                            key={x.id}
                            lat={x.coords.lat}
                            lng={x.coords.lng}
                            onDeleteMarker={()=>onDeleteMarker(x.id, item.id)}
                            isNew={x.isNew}
                        />
                    )
                })}
                {selected && (
                    <AddLocationButton onClick={onAddPin}>
                        <div>Add New Pin</div>
                    </AddLocationButton>
                )}
            </SectionItem>
        )
    }

    return (
        <ContentView>
            <SectionItem title="Select a Device" display={deviceList?.length}>
                {deviceList.map(renderDeviceItems)}
            </SectionItem>
            {selectedDevice && <div style={{ marginBottom: 5, fontWeight:'bold', marginTop: 20 }}>{selectedDevice} Service Groups</div>}
            {selectedServiceGroups.map(renderServiceGroups)}
            {deviceList.length > 0 && selectedDevice && (
                <SaveChangesView onClick={onSaveServiceGroupRegions}>
                    <div>Save Changes</div>
                </SaveChangesView>
            )}
        </ContentView>
    )
}

function mapState(state){
    return {
        deviceList: state.Map.deviceList,
        selectedDevice: state.Map.selectedDevice,
        selectedServiceGroups: state.Map.selectedServiceGroups,
        serviceGroupCoords: state.Map.serviceGroupCoords,
        selectedServiceGroup: state.Map.selectedServiceGroup,
        serviceGroupRegions: state.Map.serviceGroupRegions,
        startPoint: state.Map.startPoint,
        lincr: state.Map.lincr
    }
}

const ContentView = styled.div`
    padding: 10px;
    /* overflow: scroll; */

    flex: 1;
`
const AddLocationButton = styled.div`
    height: 20;
    width: 150;
    background-color: lightgreen;
    display: flex;
    justify-content: center ;
    align-items: center;
    padding: 10px;
    :hover{
        cursor: pointer;
    }
    margin-top: 10px;
`
const ServiceGroupOptions = styled.div`
    margin-top: 20px;
`
const CoordinateView = styled.div`
    flex: 1;
    margin: 7px 0px;
    display: flex;
    align-items: center ;
`
const Coords = styled.div`
    display: flex;
    align-items: center ;
    padding: 10px;
    >div{
        flex:1 ;
        text-align: left;
    }
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    ${p=>p.isNew && css`border:solid 2px blue;`}
`
const DeleteButton = styled.div`
    width: 25px;
    border: 1px solid;
    height: 100%;
    padding: 10px;
    margin-left: 5px;
    :hover{
        cursor: pointer;
    }
    ${p=>p.isNew && css`border:solid 2px blue;`}
`
const SaveChangesView = styled.div`
    width: 250px;
    height: 20px;
    padding: 10px;
    background-color: lightgreen;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    :hover{
        cursor: pointer;
    }
`
const BackButton= styled.div`
    width: fit-content;
    margin-right: auto;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    >div{
        margin-left: 10px;
    }
    :hover{
        cursor: pointer;
    }

`
