import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { gotLincr } from '../../../reducer/map'
import { signOut } from '../../../reducer/user'
import { changeScreen, WEB_ADMIN_MAPS_SCREEN } from '../../../reducer/user'

function LincrItem({ lincrID, Name, onClick }){
    return (
        <LincrItemView onClick={onClick}>
            <div>LincrID: {lincrID}</div>
            <div>Lincr Name: {Name}</div>
        </LincrItemView>
    )
}

export default function Contents({ lincrs=[] }) {
    const dispatch = useDispatch()

    function onClickLincr(lincrID){
        const lincr = lincrs.find(x=>x.lincrID === lincrID)
        let startPoint = { }
        let serviceGroupRegions = { }
        for (let { DisplayName, Type } of lincr.DeviceList){
            if (Type !== "UBR10012") continue
            const device = lincr[DisplayName]
            if (device.mapCenter) startPoint = device.mapCenter
            serviceGroupRegions = { ...serviceGroupRegions, ...(device.serviceGroupRegions || { }) }
        }
        dispatch(changeScreen(WEB_ADMIN_MAPS_SCREEN))
        dispatch(gotLincr(lincr, serviceGroupRegions, startPoint))
    }

    function handleSignOut(){
        signOut(dispatch)
    }

    function renderLincrItems(item){
        return (
            <LincrItem
                lincrID={item.lincrID}
                Name={item.Name}
                onClick={()=>onClickLincr(item.lincrID)}
            />
        )
    }

    return (
        <ContentsView>
            <div style={{ marginTop: 20, marginBottom: 10, fontWeight: 'bold'}}>Select a Lincr</div>
            {lincrs.map(renderLincrItems)}
            <SignOut onClick={handleSignOut}>
                <div>Sign Out</div>
            </SignOut>
        </ContentsView>
    )
}

const ContentsView = styled.div`
    background-color: #ffffff;
    width: 50%;
    min-width: 300px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 10px;
    margin: auto;
`
const LincrItemView = styled.div`
    /* height: 100px; */
    border: solid 1px;
    padding: 5px;
    margin-bottom: 10px;
    :hover {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        cursor: pointer;
    }
`
const SignOut = styled.div`
    width: 200px;
    border: solid 1px;
    padding: 5px;
    margin: 20px auto;
    :hover {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        cursor: pointer;
    }
`
