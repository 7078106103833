import { firestore, auth } from '../Firebase'
export const LOG_IN_SCREEN = 'LOG_IN_SCREEN'
export const WEB_ADMIN_DASH_BOARD_SCREEN = 'WEB_ADMIN_DASH_BOARD_SCREEN'
export const WEB_ADMIN_MAPS_SCREEN = 'WEB_ADMIN_MAPS_SCREEN'

export const screenTypes = {
    LOG_IN_SCREEN,
    WEB_ADMIN_DASH_BOARD_SCREEN,
    WEB_ADMIN_MAPS_SCREEN
}

// CONSTANTSA
const USER_SIGNIN = 'USER_SIGNIN'
export const CHANGE_SCREEN_PAGE = 'CHANGE_SCREEN_PAGE'
export const SIGN_OUT = 'SIGN_OUT'

// thunks
export const signUserIn = (user) => ({
    type: USER_SIGNIN,
    user
})

export const changeScreen= (screen) => ({
    type: CHANGE_SCREEN_PAGE,
    screen
})

const signedOut = ()=>({
    type: SIGN_OUT,
})

export const signOut = async (dispatch) => {
    await auth.signOut()
    dispatch(signedOut())
}

const initState = {
    userLoggedIn: false,
    screen: LOG_IN_SCREEN,
    user: { },
    initLoading: true,
}

export default (state = initState, action) => {
    switch (action.type) {
        case USER_SIGNIN:
            return { ...state, user: action.user, screen: WEB_ADMIN_DASH_BOARD_SCREEN, initLoading: false }
        case CHANGE_SCREEN_PAGE:
            if (!screenTypes[action.screen]) return state
            return { ...state, screen: action.screen, initLoading: false }
        case SIGN_OUT:
            return { ...initState, initLoading: false }
        default:
            return state
    }
}
