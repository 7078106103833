import React from 'react'
import styled from 'styled-components'

export default function RoundCheckBox({ checked, onChange }) {

    console.log({ checked })
    return (
        <RoundCheckBoxView>
            <label class="switch">
                <input type="checkbox" checked={checked} onChange={(e)=>{
                    console.log({ e })
                    onChange()
                }}/>
                <span class="slider round"></span>
            </label>
        </RoundCheckBoxView>
    )
}

const RoundCheckBoxView = styled.div`
    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 28px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: #2196F3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(32px);
        -ms-transform: translateX(32px);
        transform: translateX(32px);
    }

    div{
        height: 100px;
        width: 100px;
        background-color: yellow;
    }
    div:size="medium" {
        background-color: red;
    }
    /* Rounded sliders */
    .slider.round {
        border-radius: 28px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
`
