import React from 'react'
import styled from 'styled-components'

function RadioButton({ selected }){
    if (selected) return (
        <SelectedRadio>

        </SelectedRadio>
    )
    return (
        <Radio>

        </Radio>
    )
}

export default function DeviceItem({ name, selected, onClick }) {
    return (
        <DeviceItemView onClick={onClick}>
            <RadioButton selected={selected} />
            <div>{name}</div>
        </DeviceItemView>
    )
}

const DeviceItemView = styled.div`
    display: flex;
    padding: 10px;
    border: 1px solid;
    align-items: center;
    margin-bottom: 10px;
    :hover{
        cursor: pointer;
    }
`
const Radio = styled.div`
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: white;
    border: solid 1px;
    margin-right: 10px;
`

const SelectedRadio = styled.div`
    margin-right: 10px;
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: black;
    border: solid 1px;
`
