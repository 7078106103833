import React from 'react';
import styled, { css } from 'styled-components'

export default function Coordinate({ lat, lng, onDeleteMarker, showDelete=true, isNew }){
    return (
        <CoordinateView>
            <Coords isNew={isNew}>
                <div>lat: {lat}</div>
                <div>lng: {lng}</div>
            </Coords>
            {showDelete && <DeleteButton onClick={onDeleteMarker} isNew={isNew}>
                <div>x</div>
            </DeleteButton>}
        </CoordinateView>
    )
}

const CoordinateView = styled.div`
    flex: 1;
    margin: 7px 0px;
    display: flex;
    align-items: center ;
`
const Coords = styled.div`
    display: flex;
    align-items: center ;
    padding: 10px;
    >div{
        flex:1 ;
        text-align: left;
    }
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    ${p=>p.isNew && css`border:solid 2px blue;`}
`
const DeleteButton = styled.div`
    width: 25px;
    border: 1px solid;
    height: 100%;
    padding: 10px;
    margin-left: 5px;
    :hover{
        cursor: pointer;
    }
    ${p=>p.isNew && css`border:solid 2px blue;`}
`
const SaveChangesView = styled.div`
    width: 250px;
    height: 20px;
    padding: 10px;
    background-color: lightgreen;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    :hover{
        cursor: pointer;
    }
`
const BackButton= styled.div`
    width: fit-content;
    margin-right: auto;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    >div{
        margin-left: 10px;
    }
    :hover{
        cursor: pointer;
    }

`
