import React, { useState } from 'react'
import { signIn } from '../../Firebase'
import styled from 'styled-components'
import { MdEmail } from 'react-icons/md'
import { RiLockPasswordFill } from 'react-icons/ri'

function TextInput({ Icon=()=>{}, placeholder, value, onChangeText, ...rest}){

    function onChange(e){
        onChangeText(e.target.value)
    }

    return (
        <TextInputView>
            <Icon size={25} style={{ marginLeft: 5 }} />
            <input value={value} onChange={onChange} {...rest}/>
        </TextInputView>
    )
}

export default function LoginScreen() {
    const [email,setEmail] = useState('')
    const [pass,setPass] = useState('')
    const [error,setError] = useState('')

    function onChangeEmail(value){
        setEmail(value)
    }

    function onChangePass(value){
        setPass(value)
    }

    async function onLogIn(){
        try {
            await signIn(email, pass)
        } catch (error) {
            setError(error.message)
        }
    }

    return (
        <LoginScreenView>
            <LoginContainer>
                <div style={{ fontWeight: 'bold' }}>Login With Telawatch Account</div>
                <TextInput Icon={MdEmail} onChangeText={onChangeEmail} />
                <TextInput Icon={RiLockPasswordFill} onChangeText={onChangePass} type='password'/>
                <ErrorMessage>
                    <div>{error}</div>
                </ErrorMessage>
                <Button onClick={onLogIn}>
                    <div>Login</div>
                </Button>
            </LoginContainer>
        </LoginScreenView>
    )
}

const LoginScreenView = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
`
const LoginContainer = styled.div`
    width: 90%;
    max-width: 500px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 20px;
    border-radius: 10px;
    margin: auto;
`
const TextInputView = styled.div`
    border: 1px solid;
    >input {
        border: 0px solid;
        flex: 1;
        margin-left: 5px;
        padding: 10px;
        border-radius: 10px;
    }
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    margin: 10px 0px;
    >div{
        margin-left: 10px;
    }
`
const ErrorMessage = styled.div`
    color: red;
`
const Button = styled.div`
    width: 200px;
    background-color: rgb(3, 121, 251);
    color: white;
    padding: 10px;
    border-radius: 10px;
    margin: 10px auto 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover{
        cursor: pointer;
    }
`
