import axios from 'axios'
import { firestore, getLincr, saveServiceGroupRegions } from '../Firebase'
import { RED_PIN, RED_CIRCLE } from '../constants/markerIcons'
import { SIGN_OUT, CHANGE_SCREEN_PAGE } from './user'
import { env } from '../env'

function uid(){
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

function copyState(obj){
    return JSON.parse(JSON.stringify(obj))
}

const defaultCoords = [
    { coords: { lat:14,lng:  7}, id: uid() },
    { coords: { lat:2,lng:  12}, id: uid() },
    { coords: { lat:6,lng:  1.5}, id: uid() },
]

const initState = {
    markers: [],
    // object of polygon coordinates this will begin empty if first time or be filled with firebase data
    // they keys are based on ModemsByCard.id
    serviceGroupRegions: { },
    // single lincr info (every thing Lincr document containes from firebase)
    // that is being edited by user in web admin maps
    lincr: { },
    // all lincrs user is authorized to view
    lincrs: [],
    // selected device from lincr that is
    selectedDevice: null,
    // service group selected by user to edit/add cordinates to
    selectedServiceGroup: null,
    selectedServiceGroups: [],
    deviceList: [],
    // the state of the currently editing service group (this will not reflect the firestore untill user saved the state)
    curServiceGroupRegion: [],

    // geolocation start point
    startPoint: { lat: null, lng: null },
    searchBounds: null,

    initLoad: true,
    // line markers identifying user info
    otherMapMarkers: [ ], // { type: LINE | POLYGON , markers: [], title: string, body: string, id: string }
    selectedPolylineId: null
}

const ADD_MARKERS = 'ADD_MARKERS'
const ADD_COORDINATE = 'ADD_COORDINATE'
const GET_LINCR = 'GET_LINCR'
const SELECT_DEVICE = 'SELECT_DEVICE'
const ADD_MARKER_TO_CURRENT_SERVICE_GROUP = 'ADD_MARKER_TO_CURRENT_SERVICE'
const CHANGE_SELECTED_SERVICE_GROUP_ID = 'CHANGE_SELECTED_SERVICE_GROUP_ID'
const UPDATE_LAT_LNG_OF_MARKER = 'UPDATE_LAT_LNG_OF_'
const REQUEST_GEOCODE = 'REQUEST_GEOCODE'
const ADD_PIN_TO_START_LOCATION = 'ADD_PIN_TO_START_LOCATION'
const DELETE_SERVICE_GROUP_MARKERER = 'DELETE_SERVICE_GROUP_MARKER'
const SAVE_SERVICEGROUP_REGIONS_TO_FIREBASE = 'SAVE_SERVICEGROUP_REGIONS_TO_FIREBASE'
const GET_USER_LINCRS = 'GET_USER_LINCRS'
const CHANGE_LINCR_FOCUS = 'CHANGE_LINCR_FOCUS'
const ADD_MARKER_TO_CUSTOM_MARKERS = 'ADD_MARKER_TO_CUSTOM_MARKERS'
const CREATE_CUSTOM_MARKERS = 'CREATE_CUSTOM_MARKERS'
const UPDATE_CUSTOM_MARKER_COORDS = 'UPDATE_CUSTOM_MARKER_COORDS'
const CHANGE_POLYLINE_SHOW_ICON = 'CHANGE_POLYLINE_SHOW_ICON'
const CHANGE_POLYLINE_MARKER_ICON = 'CHANGE_POLYLINE_MARKER_ICON'
const DELETE_POLYLINE_MARKER = 'DELETE_POLYLINE_MARKER'
const HIGHLIGHT_POLYLINE_MARKER = 'HIGHLIGHT_POLYLINE_MARKER'
const POLYLINE_SPLIT_GROUP = 'POLYLINE_SPLIT_GROUP'
const CHANGE_SELECTED_POLYLINE_ID = 'CHANGE_SELECTED_POLYLINE_ID'

function isValidDeviceType(type){
    const VALID_DEVICE_TYPES = {
        'UBR10012': true,
        'C100G': true,
    }
    return VALID_DEVICE_TYPES[type]
}

export const gotLincr = (lincr, serviceGroupRegions, startPoint)=>({
    type: GET_LINCR,
    lincr,
    serviceGroupRegions,
    startPoint
});

export const addMarkers = (markers)=>({
    type: ADD_MARKERS,
    markers
})

export const selectDevice = (deviceName)=>({
    type: SELECT_DEVICE,
    deviceName
})

export const addToCurServiceGroup = (coords, serviceGroupId)=>({
    type: ADD_MARKER_TO_CURRENT_SERVICE_GROUP,
    coords,
    serviceGroupId
})

export const changeSelectedServiceGroupID = (id)=>({
    type: CHANGE_SELECTED_SERVICE_GROUP_ID,
    id
})

export const gotGeoCode = (coords, bounds)=>({
    type: REQUEST_GEOCODE,
    coords
})

export const addPinToStartLocation = (pin)=>({
    type: ADD_PIN_TO_START_LOCATION
})

export const deletServiceGroupMarker = (markerId,serviceGroupId)=>({
    type: DELETE_SERVICE_GROUP_MARKERER,
    markerId,
    serviceGroupId
})

export const gotUserLincrs = (lincrs)=>({
    type: GET_USER_LINCRS,
    lincrs
})

export const createCustomGroup = (title,body,color, icon)=>({
    type: CREATE_CUSTOM_MARKERS,
    title,
    body,
    color,
    icon
})

export const addCoordsToCustomMarkers = (id,coords)=>({
    type: ADD_MARKER_TO_CUSTOM_MARKERS,
    id,
    coords
})

export const updateCustomMarkerCoords = (markerId, coordsId, coords)=>({
    type: UPDATE_CUSTOM_MARKER_COORDS,
    markerId,
    coordsId,
    coords
})

export const changePolylineShowIcon = (polyLineId, markerId,showIcon) => ({
    type: CHANGE_POLYLINE_SHOW_ICON,
    polyLineId,
    markerId,
    showIcon
})

export const changePolyLineMarkerIcon = (polyLineId, markerId, newIcon)=>({
    type: CHANGE_POLYLINE_MARKER_ICON,
    polyLineId, markerId, newIcon
})

export const deletePolylineMarker = (polyLineId, markerId)=>({
    type: DELETE_POLYLINE_MARKER,
    polyLineId,
    markerId
})

export const highlightPolylineMarker = (polyLineId, markerId)=>({
    type: HIGHLIGHT_POLYLINE_MARKER,
    polyLineId,
    markerId
})

export const polylineSplitGroup = (polyLineId, markerId, groupName)=>({
    type: POLYLINE_SPLIT_GROUP,
    polyLineId,
    markerId,
    groupName
})

export const changeSelectedPolylineId = (polyLineId)=>({
    type: CHANGE_SELECTED_POLYLINE_ID,
    polyLineId
})

export const changeLincrFocus = (lincr, dispatch)=>{
    let startPoint = { }
    let serviceGroupRegions = { }
    for (let { DisplayName, Type } of lincr.DeviceList){
        if (!isValidDeviceType(Type)) continue
        const device = lincr[DisplayName]
        if (device.mapCenter) startPoint = device.mapCenter
        serviceGroupRegions = { ...serviceGroupRegions, ...(device.serviceGroupRegions || { }) }
    }
    dispatch(gotLincr(lincr, serviceGroupRegions, startPoint))
}

// thunk like
export const requestLincr = async (lincrID,dispatch)=>{
    const lincr = await getLincr(lincrID)
    let startPoint = { }
    let serviceGroupRegions = { }
    for (let { DisplayName, Type } of lincr.DeviceList){
        if (!isValidDeviceType(Type)) continue
        const device = lincr[DisplayName]
        if (device.mapCenter) startPoint = device.mapCenter
        serviceGroupRegions = { ...serviceGroupRegions, ...(device.serviceGroupRegions || { }) }
    }
    dispatch(gotLincr(lincr, serviceGroupRegions, startPoint))
}

export const addCoordinate = (coords)=>({
    type: ADD_COORDINATE,
    coords
})

export const updateLatLngOfMarker = (id,coords)=>({
    type: UPDATE_LAT_LNG_OF_MARKER,
    id,
    coords
})

export const requestGeocode = async (location,dispatch) => {
    const { data: { coords, success } } = await axios.get(env.endPoint('/api/maplinks/address/' + location))
    if (!success) return alert('There was an error please try again')
    dispatch(gotGeoCode(coords))
}

export const saveServiceGroupRegionsToFirebase = async (lincrID,deviceName,serviceGroupRegions, mapCenter, dispatch)=>{
    await saveServiceGroupRegions(lincrID, deviceName, serviceGroupRegions, mapCenter)
}

export const getUserLincrs = async (uid, dispatch)=>{
    const lincrs = (await firestore.collection("Lincrs")
                .where("AuthUsers", "array-contains-any", [uid]).get())
                .docs.map(x=>({
                    ...x.data(),
                    lincrID: x.id
                }))
    dispatch(gotUserLincrs(lincrs))
}


export default (state=initState, action) =>{
    switch (action.type){
        case ADD_MARKERS:{
            return {
                ...state,
                markers: action.markers,
                serviceGroupRegions:{
                    ...state.serviceGroupRegions,
                    [state.selectedServiceGroup]: action.markers
                }
            }
        }
        case ADD_COORDINATE:{
            return { ...state, markers: [...state.markers, { coords: action.coords, id: uid() }] }
        }
        case GET_LINCR:{
            const deviceList = action?.lincr?.DeviceList.filter(x=>isValidDeviceType(x.Type))
            let serviceGroupRegions = action.serviceGroupRegions || { }
            return { ...state, lincr: action.lincr, deviceList, serviceGroupRegions, startPoint: action.startPoint ?? { lat: null, lng: null } }
        }
        case SELECT_DEVICE:{
            const device = state.lincr.DeviceList.find(x=>x.DisplayName === action.deviceName)
            const Type = device.Type || ''
            switch (Type) {
                case 'UBR10012':{
                    const selectedServiceGroups = state.lincr[action.deviceName]?.ModemsByCard?.filter(x=>x.id !== 'Total') || []
                    let selectedServiceGroup = null
                    if (selectedServiceGroups.length > 0) {
                        selectedServiceGroup = selectedServiceGroups[0].id
                    }
                    const curServiceGroupRegion = state.serviceGroupRegions[selectedServiceGroup] || []
                    const startPoint = state.lincr?.[action.deviceName]?.mapCenter || { }
                    return { ...state, selectedDevice: action.deviceName, selectedServiceGroups, selectedServiceGroup, curServiceGroupRegion, startPoint }
                }
                case 'C100G':{
                    const cardSummary = state.lincr[action.deviceName].cardSummary
                    const selectedServiceGroups = Object.keys(cardSummary).reduce((a,c)=>{
                        for (let port of Object.keys(cardSummary[c])){
                            const isDownstream = Number(c) < 8
                            if (c === 'Total' || isDownstream) continue
                            a.push({
                                id: `${c}/${port}`,
                                active: cardSummary[c][port].onlineCM,
                                total: null
                            })
                        }
                        return a
                    },[])
                    let selectedServiceGroup = null
                    if (selectedServiceGroups.length > 0) {
                        selectedServiceGroup = selectedServiceGroups[0].id
                    }
                    const serviceGroupRegions = state.lincr[action.deviceName].serviceGroupRegions
                    const curServiceGroupRegion = serviceGroupRegions[selectedServiceGroup] || []
                    const startPoint = state.lincr?.[action.deviceName]?.mapCenter || { }
                    return { ...state, selectedDevice: action.deviceName, selectedServiceGroups, selectedServiceGroup, curServiceGroupRegion, serviceGroupRegions: {...state.serviceGroupRegions, ...serviceGroupRegions }, startPoint }
                }
                default:
                    return state
            }
        }
        case ADD_MARKER_TO_CURRENT_SERVICE_GROUP:{
            const copyServiceGroupRegions = JSON.parse(JSON.stringify(state.serviceGroupRegions))
            const id = uid()
            if (!copyServiceGroupRegions[action.serviceGroupId]){
                copyServiceGroupRegions[action.serviceGroupId] = []
            }
            const curServiceGroupRegion = copyState(state.curServiceGroupRegion)

            const oldNewFromCurServiceGroup = curServiceGroupRegion.find(x=>x.isNew)
            const oldNewFromServiceGroupRegions =copyServiceGroupRegions?.[action.serviceGroupId]?.find(x=>x.isNew)
            // delete old "new" identifier
            if (oldNewFromCurServiceGroup) delete oldNewFromCurServiceGroup.isNew
            if (oldNewFromServiceGroupRegions) delete oldNewFromServiceGroupRegions.isNew

            curServiceGroupRegion.push({ coords: action.coords, id: id, isNew: true })
            copyServiceGroupRegions[action.serviceGroupId].push({ coords: action.coords, id: id, isNew: true })
            return {
                ...state,
                curServiceGroupRegion: curServiceGroupRegion,
                serviceGroupRegions: copyServiceGroupRegions
            }
        }
        case CHANGE_SELECTED_SERVICE_GROUP_ID:{
            const { selectedServiceGroup }  = state
            const curServiceGroupRegion = state.serviceGroupRegions[action.id] || []
            const serviceGroupRegions = copyState(state.serviceGroupRegions)
            const oldNew =serviceGroupRegions?.[selectedServiceGroup]?.find(x=>x.isNew)
            if (oldNew) delete oldNew.isNew
            return { ...state, selectedServiceGroup: action.id, curServiceGroupRegion, serviceGroupRegions }
        }
        case UPDATE_LAT_LNG_OF_MARKER:{
            const { id, coords } = action
            const serviceGroupId = state.selectedServiceGroup
            const copyMarkers = copyState(state.curServiceGroupRegion)
            const serviceGroupRegions = copyState(state.serviceGroupRegions)
            const exist = copyMarkers.find(x=>x.id === id)
            if (exist){
                exist.coords = coords
                serviceGroupRegions[serviceGroupId] = copyMarkers
            }
            return { ...state, curServiceGroupRegion: copyMarkers, serviceGroupRegions }
        }
        case REQUEST_GEOCODE:
            return { ...state, startPoint: action.coords, searchBounds: action.bounds }
        case ADD_PIN_TO_START_LOCATION:{
            const startPoint = state.startPoint
            const { selectedServiceGroup: serviceGroupId } = state

            const serviceGroupRegions = copyState(state.serviceGroupRegions)
            const curServiceGroupRegion = copyState(state.curServiceGroupRegion)
            const newMarker = { coords: startPoint, id: uid(), isNew: true }
            curServiceGroupRegion.push(newMarker)
            if (!serviceGroupRegions[serviceGroupId]){
                serviceGroupRegions[serviceGroupId] = []
            }

            const oldNewFromCurServiceGroup = curServiceGroupRegion.find(x=>x.isNew)
            const oldNewFromServiceGroupRegions =serviceGroupRegions?.[action.serviceGroupId]?.find(x=>x.isNew)
            // delete old "new" identifier
            if (oldNewFromCurServiceGroup) delete oldNewFromCurServiceGroup.isNew
            if (oldNewFromServiceGroupRegions) delete oldNewFromServiceGroupRegions.isNew

            serviceGroupRegions[serviceGroupId].push(newMarker)
            return { ...state, serviceGroupRegions, curServiceGroupRegion}
        }
        case DELETE_SERVICE_GROUP_MARKERER:{
            const { markerId, serviceGroupId, selectedServiceGroup } = action
            const serviceGroupRegions = copyState(state.serviceGroupRegions)
            const newObj = Object.keys(serviceGroupRegions).reduce((a, id)=>{
                let markers = serviceGroupRegions[id]
                if (id === serviceGroupId){
                    // filter out the removed marker
                    markers = markers.filter(x=>x.id !== markerId)
                }
                a[id] = markers
                return a
            },{  })

            const curServiceGroupRegion = copyState(state.curServiceGroupRegion).filter(x=>x.id !== markerId)
            return { ...state, serviceGroupRegions: newObj, curServiceGroupRegion }
        }
        case GET_USER_LINCRS:{
            return { ...state, lincrs: action.lincrs }
        }
        case CHANGE_SCREEN_PAGE:{
            return { ...state, ...initState, lincrs: state.lincrs }
        }
        case SIGN_OUT:
            return initState
        case CREATE_CUSTOM_MARKERS:{
            const { title, body, color, icon } = action
            const id = uid()
            return {
                ...state,
                otherMapMarkers: [...state.otherMapMarkers, { title, body, id, color, icon, path: [] }]
            }
        }
        case ADD_MARKER_TO_CUSTOM_MARKERS:{
            const otherMapMarkers = copyState(state.otherMapMarkers)
            const exist = otherMapMarkers.find(x=>x.id === action.id)
            if (exist) {
                exist.path.push({ coords: action.coords, id: uid(), showIcon: true })
            }
            return { ...state, otherMapMarkers: otherMapMarkers }
        }
        case UPDATE_CUSTOM_MARKER_COORDS:{
            const { coordsId, markerId, coords } = action
            const otherMapMarkers = copyState(state.otherMapMarkers)
            const group = otherMapMarkers.find(x=>x.id === markerId)
            if (group) {
                const coordMarker = group.path.find(x=>x.id === coordsId)
                coordMarker.coords = coords
                if (coordMarker.splitGroups?.length){
                    for (let { polyLineId, markerId } of coordMarker.splitGroups){
                        const polyLine = otherMapMarkers.find(x=>x.id === polyLineId)
                        const marker = polyLine.path.find(x=>x.id === markerId)
                        marker.coords = coords
                    }
                }
            }
            return { ...state, otherMapMarkers: otherMapMarkers }
        }
        case CHANGE_POLYLINE_SHOW_ICON:{
            const { polyLineId, showIcon, markerId } = action
            const polyLines = copyState(state.otherMapMarkers)
            const polyLine = polyLines.find(x=>x.id === polyLineId)
            const marker = (polyLine?.path ?? []).find(x=>x.id === markerId)
            if (marker) {
                marker.showIcon = showIcon
            }
            return { ...state, otherMapMarkers: polyLines }
        }
        case CHANGE_POLYLINE_MARKER_ICON:{
            const { polyLineId, markerId, newIcon } = action
            const polyLines = copyState(state.otherMapMarkers)
            const polyLine = polyLines.find(x=>x.id === polyLineId)
            const marker = (polyLine.path ?? []).find(x=>x.id === markerId)
            if (marker){
                marker.icon = newIcon
            }
            return { ...state, otherMapMarkers: polyLines }
        }
        case DELETE_POLYLINE_MARKER:{
            const { polyLineId, markerId } = action
            const otherMapMarkers = copyState(state.otherMapMarkers)
            const polyLine = otherMapMarkers.find(x=>x.id === polyLineId)
            const index = (polyLine.path || []).findIndex(x=>x.id === markerId)

            const marker = polyLine.path[index]
            const splitGroups = marker?.splitGroups || []
            for (let item of splitGroups){
                const polyLineId = item.polyLineId
                const index = otherMapMarkers.findIndex((x)=>x.id === polyLineId)
                if (index) otherMapMarkers.splice(index, 1)
            }

            if (index !== -1) {
                polyLine.path.splice(index, 1)
            }
            return { ...state, otherMapMarkers }

        }
        case HIGHLIGHT_POLYLINE_MARKER:{
            const { polyLineId, markerId } = action
            const otherMapMarkers = copyState(state.otherMapMarkers).map(x=>{
                for (let i = 0; i < x?.path?.length; i++){
                    x.path[i].focus = false
                }
                return x
            })
            const polyLine = otherMapMarkers.find(x=>x.id === polyLineId)
            const mark = (polyLine.path || []).find(x=>x.id === markerId)
            if (mark) {
                mark.focus = !(mark.focus ?? false)
            }
            return { ...state, otherMapMarkers }
        }
        case POLYLINE_SPLIT_GROUP:{
            const { polyLineId, markerId, groupName: title } = action
            const otherMapMarkers = copyState(state.otherMapMarkers)
            const polyLine = otherMapMarkers.find(x=>x.id === polyLineId)
            const marker = (polyLine.path ?? []).find(x=>x.id === markerId)
            if (!marker) return state
            // need a way to update this marker when the paret marker gets moved around
            if (!marker.splitGroups) marker.splitGroups = []
            marker.focus = false
            const _polyLineId = uid()
            const _markerId = uid()
            marker.splitGroups.push({ polyLineId: _polyLineId, markerId: _markerId })
            const startCoords = marker.coords
            const newMark = { id: _markerId, coords: startCoords, showIcon: false }
            otherMapMarkers.push({ path: [ newMark ], title, body: '', icon: RED_PIN, id: _polyLineId })
            // select new group
            return {
                ...state,
                otherMapMarkers: otherMapMarkers,
                selectedPolylineId: _polyLineId
            }
        }
        case CHANGE_SELECTED_POLYLINE_ID:{
            return { ...state, selectedPolylineId: action.polyLineId }
        }
        default:
            return state
    }
}
