import React from 'react';
import { useSelector } from 'react-redux'
function mapState(state){
    return {
        getMarker:(id)=> state.Map.curServiceGroupRegion.find(x=>x.id === id)
    }
}

const Marker = ({ onClick, onDrag=()=>{}, exist, useExist, ...options}) => {
    const [marker, setMarker] = React.useState(null);
    const { getMarker } = useSelector(mapState)
    const makrerInfo = getMarker(options.id)

    React.useEffect(() => {
        if (!marker) {
            const newMarker = new window.google.maps.Marker()
            window.google.maps.event.addDomListener(newMarker, 'click', onClick);
            if (onDrag) window.google.maps.event.addListener(newMarker, "dragend", (e)=>onDrag(e,options));
            setMarker(newMarker);
        }
        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
                setMarker(null)
            }
        };
    }, [marker, options?.position]);

    React.useEffect(() => {
        if (marker) {
            const defaultOptions = {...options}
            const position = makrerInfo.coords
            if (makrerInfo.isNew) {
                defaultOptions.icon = 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
            }
            marker.setOptions({ ...defaultOptions, position });
        }
    }, [marker, options]);
    return null;
};

function comp(prev,next){
    return prev?.position?.lat !== next?.position?.lat &&  prev?.position?.lng !== next?.position?.lng
}

// export default React.memo(Marker, comp)
export default Marker
