import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserLincrs } from '../../reducer/map'
import Contents from './components/Contents'
import styled from 'styled-components'

export default function DashboardScreen() {
    const dispatch = useDispatch()
    const { lincrs, user } = useSelector(mapState)
    useEffect(() => {
        getUserLincrs(user?.uid, dispatch)
    },[])
    return (
        <DashboardScreenView>
            <Contents lincrs={lincrs}/>
        </DashboardScreenView>
    )
}

function mapState(state) {
    return {
        lincrs: state.Map.lincrs,
        user: state.User.user
    }
}

const DashboardScreenView = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
`
