import React, { useEffect, useState } from 'react';
import './App.css';
import GoogleMap from'./maps'
import axios from 'axios'
import WebAdminApp from './pages/webadmin/WebAdminApp'
import { changeScreen, LOG_IN_SCREEN, screenTypes, signUserIn } from './reducer/user';
import { useDispatch, useSelector } from 'react-redux'
import LoginScreen from './pages/auth/LoginScreen'
import DashboardScreen from './pages/dashboard/DashboardScreen'
import { firestore, auth } from './Firebase'

const defaultOptions = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
}

function Loading(){
    return (
        <div style={{ height: "100%", width: "100%", display: "flex", alignItems:'center', justifyContent: "center"}}>
            <div>Loading</div>
        </div>
    )
}

function App() {
    const dispatch = useDispatch()
    const { selectedScreen, initLoading } = useSelector(mapState)

    useEffect(() => {
        return auth.onAuthStateChanged((user) => {
            if (user) dispatch(signUserIn(user))
            else dispatch(changeScreen(LOG_IN_SCREEN))
        })
    },[])
    if (initLoading) return <Loading />
    return (
        <div className="App" style={{ height: '100vh', width: '100vw' }}>
            {selectedScreen === screenTypes.LOG_IN_SCREEN && <LoginScreen />}
            {selectedScreen === screenTypes.WEB_ADMIN_DASH_BOARD_SCREEN && <DashboardScreen />}
            {selectedScreen === screenTypes.WEB_ADMIN_MAPS_SCREEN && <WebAdminApp />}
        </div>
    );
}

function mapState(state){
    return {
        selectedScreen: state.User.screen,
        initLoading: state.User.initLoading
    }
}

export default App;
