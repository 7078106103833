export const DARK_TRIANGLE = 'https://img.icons8.com/material-two-tone/344/triangle-stroked.png'
export const TRIANGLE = 'https://img.icons8.com/material-sharp/344/triangle-stroked.png'
export const RED_PIN = 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'
export const RED_CIRCLE = require('../images/red-circle.png')

export const UTILITY_POLE = require('../images/utility_pole.png')
export const INLINE_EQUALIZER = require('../images/inline_equalizer.png')
export const LINE_EXTENDER_W_AGC = require('../images/line_extender_w_AGC.png')
export const BRIDGER_AMPS_2 = require('../images/bridger_amps_2.png')
export const EIGHT_WAY_TAP = require('../images/8_way_tap.png')
export const FOUR_WAY_TAP = require('../images/4_way_tap-removebg-preview.png')
export const DC_SPLITTER = require('../images/DC_splitter.png')
//
export const POWER_INSTANCE = require('../images/power_inserter.png')
export const POWER_SUPPLY = require('../images/power_supply.png')
export const HEAD_END = require('../images/head_end.png')
export const REMOTE_HUB_LOCATION = require('../images/remote_hub_location.png')
