import React, { useRef, useEffect, useState } from 'react'
import { createCustomEqual } from "fast-equals";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import RedPin from '../markerIcons/red.png';
import YellowPin from '../markerIcons/yellow.png';
import GreenPin from '../markerIcons/green.png';
import { useSelector, useDispatch } from 'react-redux'
import { MdCable } from "react-icons/md"
import { updateCustomMarkerCoords, highlightPolylineMarker } from '../reducer/map'


const svg = window.btoa(`
<svg fill="green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    <circle cx="120" cy="120" opacity=".6" r="70" />
    <circle cx="120" cy="120" opacity=".3" r="90" />
    <circle cx="120" cy="120" opacity=".2" r="110" />
    <circle cx="120" cy="120" opacity=".1" r="130" />
</svg>`);

const defaultOptions = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
}

function mapState(state){
    return {
        polygonArr: state.Map.curServiceGroupRegion.map(x=>x.coords) || [],
        startPoint: state.Map.startPoint,
        serviceGroupRegions: state.Map.serviceGroupRegions,
        selectedServiceGroupId: state.Map.selectedServiceGroup,
        searchBounds: state.Map.searchBounds,
        selectedDevice: state.Map.selectedDevice,
        otherMapMarkers: state.Map.otherMapMarkers
    }
}

function Map({ onClick, onIdle, children, style, clusterMarkers, useCluster=false, count,  onClickMarker, onClickPolygon, ...options }) {
    const dispatch = useDispatch()
    const { polygonArr, startPoint, serviceGroupRegions, selectedServiceGroupId, searchBounds, selectedDevice, otherMapMarkers } = useSelector(mapState)
    const ref = useRef(null);
    const [map, setMap] = useState();
    const [cluster, setCluster] = useState(null);
    const [plyMarkers,setPolygonMarkers] = useState(null);
    const [otherRegions,setOtherRegions] = useState(null);
    const [_otherMapMarkers,setOtherMapMakers] = useState({ });

    function onClickMap(e){
        if (onClick){
            const { latLng } = e
            const lat = latLng.lat()
            const lng = latLng.lng()
        }
    }

    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }
    }, [ref, map]);

    useDeepCompareEffectForMaps(() => {
        if (map) {
            map.setOptions(options);
        }
    }, [map, options]);

    useEffect(()=>{
        if (polygonArr?.length && map){
            if (plyMarkers) plyMarkers.setMap(null)
            const bermudaTriangle = new window.google.maps.Polygon({
                paths: polygonArr,
                ...defaultOptions
            });
            bermudaTriangle.setMap(map);
            setPolygonMarkers(bermudaTriangle)
        }else if (plyMarkers && !polygonArr.length) {
            // user switch to different device group
            plyMarkers.setMap(null)
        }
    },[polygonArr, map])

    // display all other service group regions
    useEffect(()=>{
        if (map && selectedDevice){
            const nonSelected = Object.keys(serviceGroupRegions).filter(x=>x !== selectedServiceGroupId)
            const obj = { }
            for (let key in otherRegions){
                if (otherRegions[key]) otherRegions[key].setMap(null)
            }
            for (let serviceGroupRegionID of nonSelected){
                const region = serviceGroupRegions[serviceGroupRegionID]
                const bermudaTriangle = new window.google.maps.Polygon({
                    paths: region.map(x=>x.coords),
                    ...defaultOptions,
                    fillColor: 'black',
                    strokeColor: 'black',

                });
                bermudaTriangle.setMap(map);
                window.google.maps.event.addListener(bermudaTriangle, 'click', (e)=>onClickPolygon(e,serviceGroupRegionID));
                obj[serviceGroupRegionID] = bermudaTriangle
            }
            setOtherRegions(obj)
        }
    },[polygonArr, serviceGroupRegions, map])

    useEffect(() => {
        if (map && otherMapMarkers.length > 0) {
            let newIds = { }
            for (let { path, color='black', icon: defaultIcon, id: markerId } of otherMapMarkers) {

                if (_otherMapMarkers[markerId]){
                    _otherMapMarkers[markerId].setMap(null)
                }

                const polyLine = new window.google.maps.Polyline({
                    path: path.map(x=>x.coords),
                    ...defaultOptions,
                    strokeColor: color,
                });
                polyLine.setMap(map);
                newIds[markerId] = polyLine
                let markers = []

                for (let p of path){
                    if (p.showIcon === false) {
                        if (_otherMapMarkers[p.id]) _otherMapMarkers[p.id].setMap(null)
                        continue
                    }
                    if (_otherMapMarkers[p.id]) _otherMapMarkers[p.id].setMap(null)
                        const marker = new window.google.maps.Marker({
                            position: p.coords,
                            map,
                            draggable: true,
                            icon: {
                                url: p.icon ?? defaultIcon,
                                scaledSize: new window.google.maps.Size(30, 30),
                                anchor: new window.google.maps.Point(15,25),
                            }
                        })
                        newIds[p.id] = marker
                        markers.push({ id: p.id, marker })
                        //
                        window.google.maps.event.addListener(marker, 'click', (e)=>{
                            dispatch(highlightPolylineMarker(markerId,p.id))
                        });
                    // }
                }
                for (let { id, marker } of markers){
                    window.google.maps.event.addListener(marker, 'dragend', function handleDrageEnd(e){
                        const lat = e.latLng.lat()
                        const lng = e.latLng.lng()
                        polyLine.setMap(null)
                        dispatch(updateCustomMarkerCoords(markerId, id, { lat, lng } ))
                    });
                }
            }
            setOtherMapMakers({ ..._otherMapMarkers, ...newIds })
        }
        // check markers exist. if the have been deleted => remove from map
        const polyLineIds = otherMapMarkers.map(x=>x.id)
        const allMarkerIds = otherMapMarkers.reduce((a,c)=>{
            const ids = c.path.map(x=>x.id)
            for( let id of ids){
                if (polyLineIds.find(x=>x === id)) continue
                a[id] = true
            }
            return a
        }, { })

        for(let id of Object.keys(_otherMapMarkers)){
            if (!allMarkerIds[id]){
                _otherMapMarkers[id].setMap(null)
                delete _otherMapMarkers[id]
            }
        }
    },[otherMapMarkers, map])

    useEffect(()=>{
        if (map && clusterMarkers?.length && useCluster && !cluster){
            const coords = clusterMarkers.map(x=>{
                const newMarker =  new window.google.maps.Marker({
                    position: x.coords,
                    icon: {
                        url: GreenPin,
                        size: { width: 60, height: 60 },
                        scaledSize: { width: 30, height: 50 }
                    },
                })
                window.google.maps.event.addDomListener(newMarker, 'click', ()=>onClickMarker(x.coords));
                return newMarker
            })
            const res = new MarkerClusterer({
                markers: coords,
                map,
                renderer: {
                    render: function ({ count, position }){
                        return new window.google.maps.Marker({
                            position,
                            icon: {
                                url: `data:image/svg+xml;base64,${svg}`,
                                scaledSize: new window.google.maps.Size(45, 45),
                            },
                            label: {
                                text: String(count),
                                color: "white",
                                fontSize: "12px",
                            },
                            title: `Cluster of ${count} markers`,
                            zIndex: 1000 + count,
                        });
                    },
                }
            });

            setCluster(res)
        }
    },[clusterMarkers])


    // events for maps
    React.useEffect(() => {
        if (map) {
            ["click", "idle"].forEach((eventName) =>
                window.google.maps.event.clearListeners(map, eventName)
            );
            if (onClick) {
                map.addListener("click", onClick);
            }

            if (onIdle) {
                map.addListener("idle", () => onIdle(map));
            }
        }
    }, [map, onClick, onIdle]);

    useEffect(() => {
        const mapCenter = map?.getCenter()?.toJSON() || { }
        const isCenterOfMap = mapCenter.lat === 0 && mapCenter.lng === 0
        if (map && startPoint.lat && startPoint.lng) {
            map.setCenter(startPoint)
            map.setZoom(15)
        }
    },[startPoint, map, searchBounds])

    return (
        <>
        <div ref={ref} style={style} />
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, { map });
                }
            })}
        </>
    )
}

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
    if (
        a instanceof window.google.maps.LatLng ||
        b instanceof window.google.maps.LatLng
    ) {
        return new window.google.maps.LatLng(a).equals(new window.google.maps.LatLng(b));
    }
    // TODO extend to other types
    // use fast-equals for other objects
    return deepEqual(a, b);
});

function useDeepCompareMemoize(value) {
    const ref = React.useRef();

    if (!deepCompareEqualsForMaps(value, ref.current)) {
        ref.current = value;
    }
    return ref.current;
}

function useDeepCompareEffectForMaps(callback, dependencies) {
    React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}


export default Map
