import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components'
import { DARK_TRIANGLE, TRIANGLE, RED_PIN, UTILITY_POLE, INLINE_EQUALIZER, LINE_EXTENDER_W_AGC, EIGHT_WAY_TAP, FOUR_WAY_TAP, DC_SPLITTER, POWER_INSTANCE, POWER_SUPPLY, HEAD_END, REMOTE_HUB_LOCATION } from '../../../constants/markerIcons'
import {  MdOutlineCallSplit } from 'react-icons/md'
import { RoundCheckBox } from '../../../components'

const icons = [
    {
        src: TRIANGLE,
        title: 'Bridger AMP'
    },
    {
        src: UTILITY_POLE,
        title: 'Utility Pole'
    },
    {
        src: INLINE_EQUALIZER,
        title: 'Inline Equalizer'
    },
    {
        src: LINE_EXTENDER_W_AGC,
        title: 'Line Extender with AGC'
    },
    ,
    {
        src: EIGHT_WAY_TAP,
        title: '8 way tap'
    },
    {
        src: FOUR_WAY_TAP,
        title: '4 way tap'
    },
    {
        src: DC_SPLITTER,
        title: 'DC Splitter'
    },
    {
        src: POWER_INSTANCE,
        title: 'Power Instance'
    },
    {
        src: POWER_SUPPLY,
        title: 'Power Supply'
    },
    {
        src: HEAD_END,
        title: 'Head End'
    },
    {
        src: REMOTE_HUB_LOCATION,
        title: 'Remote Hub Location'
    },
]

export default function CustomCordinate({ lat, lng, icon, color, showIcon, onDeleteMarker, isNew, focus, onChangeShowIcon, onChangeIcon, onSplitGroup }){
    const [show,setShow] = useState(false)
    const [splitGroupName,setSplitGroupName] = useState('')
    function onShow(){
        setShow(!show)
    }

    function onChangeSplitGroupName(e){
        const { value } = e.target
        setSplitGroupName(value)
    }

    function onHandleSplitGroup(){
        onSplitGroup(splitGroupName)
        setSplitGroupName('')
    }

    useEffect(() => {
        setShow(focus)
    },[focus])

    function rendrItems(item){
        return (
            <Image selected={icon === item.src && icon} onClick={()=>onChangeIcon(item.src)}>
                <img src={item.src} />
                <div>{item.title}</div>
            </Image>
        )
    }

    return (
        <CoordinateView show={show}>
            <div style={{ display: 'flex', flexDirection:'row', width: '100%' }}>
                <Coords isNew={isNew} onClick={(onShow)}>
                    <div>lat: {lat}</div>
                    <div>lng: {lng}</div>
                </Coords>
                <DeleteButton onClick={onDeleteMarker} isNew={isNew}>
                    <div>x</div>
                </DeleteButton>
            </div>
            {(show) && (
                <CoordinateDetail>
                    <div style={{ display: 'flex' }}>
                        <HasOwnIcon>
                            <div>Has Own Icon: {icon ? 'true' : 'false'}</div>
                        </HasOwnIcon>
                        <ShowButton>
                            <div>Show Icon</div>
                            <RoundCheckBox checked={icon && showIcon ? true : false} onChange={onChangeShowIcon}/>
                        </ShowButton>
                    </div>
                    <ChangeIconView>
                        <p>Change Icon</p>
                        <div className="icons">
                            {icons.map(rendrItems)}
                        </div>
                        <SplitGroupView>
                            <Input>
                                <input placeholder={'New Group Name'} splitGroupName={splitGroupName} onChange={onChangeSplitGroupName} />
                            </Input>
                            <SplitGroupButton onClick={onHandleSplitGroup}>
                                <MdOutlineCallSplit style={{ marginRight: 10 }} />
                                <div>Split</div>
                            </SplitGroupButton>
                        </SplitGroupView>
                    </ChangeIconView>
                </CoordinateDetail>
            )}
        </CoordinateView>
    )
}

const CoordinateView = styled.div`
    flex: 1;
    margin: 7px 0px;
    display: flex;
    align-items: center ;
    flex-direction: column;
    ${p=>p.show && `border: solid 1px; background-color: white; padding: 5px;`}
    border-collapse: collapse;
`
const Coords = styled.div`
border-collapse: collapse;
    border: solid 1px;
    display: flex;
    align-items: center ;
    padding: 10px;
    >div{
        flex:1 ;
        text-align: left;
    }
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    ${p=>p.isNew && css`border:solid 2px blue;`}
    :hover{
        cursor: pointer;
    }
`
const DeleteButton = styled.div`
    width: 25px;
    border: 1px solid;
    height: 100%;
    padding: 10px;
    margin-left: 5px;
    :hover{
        cursor: pointer;
    }
    ${p=>p.isNew && css`border:solid 2px blue;`}
`
const SaveChangesView = styled.div`
    width: 250px;
    height: 20px;
    padding: 10px;
    background-color: lightgreen;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    :hover{
        cursor: pointer;
    }
`
const BackButton= styled.div`
    width: fit-content;
    margin-right: auto;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    >div{
        margin-left: 10px;
    }
    :hover{
        cursor: pointer;
    }

`
const CoordinateDetail = styled.div`
    /* width: calc(100% - 22px); */
    width: 100%;
    padding: 10px 0px;
`
const ChangeIconView  = styled.div`
    margin-top: 10px;
    >div{
        display: flex;
    }
    >p{
        margin: 0px;
        text-align: left;
    }
    .icons{
        overflow-x: scroll;
        margin-top: 10px;
    }
`
const Image = styled.div`
    margin-right: 10px;
    ${p=>p.selected && 'border: solid 1px; padding: 2px;'}
    ${p=>!p.selected && 'padding: 3px;'}
    > img{
        height: 30px;
        width: 30px;
        :hover{
            cursor: pointer;
        }
    }
    >div{
        font-size: 8px;
        flex: 1;
    }
`

const HasOwnIcon = styled.div`
    border: solid 1px;
    padding: 5px;
`

const ShowButton = styled.div`
    border: solid 1px;
    padding: 5px;
    display: flex;
    align-items: center;
    >div{
        margin-right: 10px;
    }
    :hover{
        cursor: pointer;
    }
    margin-left: 10px;
`
const SplitGroupView = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
    /* margin: 10px auto 0px auto; */
`
const SplitGroupButton = styled.div`
    height: 40px;
    width: 150px;
    background: lightgrey;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover{
        cursor: pointer;
    }
    margin-left: 10px;
`
const Input = styled.div`
    display: flex;
    flex: 1;
    >input {
        height: 38px;
        flex: 1;
        border: 1px solid;
        padding: 0px;
        padding: 0px 5px;
    }
`
